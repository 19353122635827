import { Image, ImageSourcePropType, View } from "react-native"

import { BASE_MARGIN, MainContainer, Screen, UiH3 } from "@components/DesignSystem"
import { PZMarkdownRenderer } from "@components/today/PzMarkdownRenderer"
import { basicStyles } from "@palette/styles"
import stylex from "@stylexjs/stylex"

import familyImg from "../../assets/images/family@2x.png"
import { DefaultThemeOverride } from "../theme/DynamicThemeChanger"

export const ServerSideBlockerScreen = (props: { header: string; bodyMD: string }) => {
  return (
    <DefaultThemeOverride colorScheme="light">
      <Screen>
        <div {...stylex.props(basicStyles.centered)}>
          <MainContainer style={{ maxWidth: 600 }}>
            <View style={{ alignItems: "center", justifyContent: "center", minWidth: 300, flex: 1, display: "flex", height: 400 }}>
              <Image
                resizeMode="contain"
                style={{ flex: 1, aspectRatio: 1, maxWidth: "70%" }}
                source={familyImg as ImageSourcePropType}
                defaultSource={familyImg as any}
                accessibilityIgnoresInvertColors
              />

              <UiH3 style={{ textAlign: "center", marginBottom: BASE_MARGIN }}>{props.header}</UiH3>

              <PZMarkdownRenderer md={props.bodyMD} neverDebug />
            </View>
          </MainContainer>
        </div>
      </Screen>
    </DefaultThemeOverride>
  )
}
