import { Fragment } from "react"
import { View, ViewStyle } from "react-native"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { AvatarSize } from "@palette/Avatar"
import { spacing } from "@palette/tokens.stylex"
import { UserDisplay$key } from "@relay/UserDisplay.graphql"

import { useIsInAppEmbed } from "../../../AppContext"
import { BASE_MARGIN, BodyTextOld, InternalLink } from "../DesignSystem"
import { UserAvatar_RAW } from "./UserAvatar"
import { UserDisplayPopover } from "./UserDisplayPopover"

const fragment = graphql`
  fragment UserDisplay on User {
    id
    username
    usernameID
    publishingName
    avatarURL
    name
  }
`

export type UserDisplayOptions = {
  noLink?: boolean
  noPopover?: boolean
  size?: AvatarSize
  mode: "text-only" | "avatar-only" | "text-and-avatar" | "avatar-and-text"
  preferDisplay?: true
  preferPublishingName?: true
  style?: ViewStyle
  textSuffix?: string
  vAlign?: "baseline" | "center"
}

export const UserDisplay = (props: { user: UserDisplay$key } & UserDisplayOptions) => {
  const user = useFragment(fragment, props.user)
  // Should this happen? No. Does it? Yes.
  if (!user) {
    console.error("UserDisplay: user is null?", user, props)
    return null
  }
  return <UserDisplay_RAW {...props} user={user} />
}

export const UserDisplay_RAW = (
  props: {
    user: { id?: string; username: string; usernameID: string; name?: string | null; avatarURL?: string; publishingName?: string | null }
  } & UserDisplayOptions,
) => {
  const isAppEmbed = useIsInAppEmbed()
  const user = props.user
  const name = props.preferPublishingName && user.publishingName ? user.publishingName : props.preferDisplay ? user.name : user.username

  const Popover = props.noPopover ? (View as any) : UserDisplayPopover

  const showAvatar = props.mode === "avatar-only" || props.mode === "avatar-and-text" || props.mode === "text-and-avatar"
  const showText = props.mode !== "avatar-only"
  const flexDirection = props.mode === "text-and-avatar" ? "row-reverse" : "row"

  const popoverProps = props.noPopover ? {} : user
  return (
    <Popover {...popoverProps}>
      <InternalLink
        disabled={props.noLink}
        screen="UserProfile"
        routeOpts={{ username: user.username, usernameID: user.usernameID }}
        aria-label={`View ${user.name}'s profile`}
        style={{
          gap: props.size === "tiny" || props.size === "small" ? spacing.small : spacing.medium,
          display: "flex",
          alignItems: props.vAlign || "baseline",
          flexDirection,
          pointerEvents: isAppEmbed ? "none" : undefined,
        }}
      >
        {showAvatar && !!user.avatarURL && !!user.username && !!user.usernameID && (
          <UserAvatar_RAW size={props.size} noLink={true} user={user as any} />
        )}
        {showText && `${name}${props.textSuffix ?? ""}`}
      </InternalLink>
    </Popover>
  )
}

// Shared component for displaying a user's name and avatar, with tooltip on hover (web) / press (mobile)
export type UsersRow = ({
  id: string
  username: string
  name: string | null | undefined
  publishingName?: string | null
} & UserDisplay$key)[]

export function UserDisplayRow(props: {
  users: UsersRow | Readonly<UsersRow>
  displayAll?: true
  style?: ViewStyle
  preferDisplay?: true
  preferPublishingName?: true
  vAlign?: "center" | "baseline"
}) {
  return (
    <>
      {props.users.length <= 1 && (
        <UserDisplay
          style={props.style}
          user={props.users[0]!}
          mode="avatar-and-text"
          size="small"
          preferDisplay={props.preferDisplay}
          preferPublishingName={props.preferPublishingName}
          vAlign={props.vAlign}
        />
      )}

      {props.users.length > 1 && !props.displayAll && (
        <View style={{ flexDirection: "row", alignItems: "center", ...props.style }}>
          <UserDisplay user={props.users[0]} mode="avatar-only" size="small" key={props.users[0].id} />
          {props.users.slice(1).map((user, i) => (
            <UserDisplay
              user={user}
              mode="avatar-only"
              size="small"
              key={user.id}
              style={{ marginLeft: -6, zIndex: 99 - i }}
              preferDisplay={props.preferDisplay}
              preferPublishingName={props.preferPublishingName}
              vAlign={props.vAlign}
            />
          ))}
          <BodyTextOld style={{ marginLeft: BASE_MARGIN / 3, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
            {props.preferPublishingName && props.users[0].publishingName
              ? props.users[0].publishingName
              : props.preferDisplay
                ? props.users[0].name
                : props.users[0].username}{" "}
            + {props.users.length - 1}
          </BodyTextOld>
        </View>
      )}

      {props.users.length > 1 && props.displayAll && (
        <View style={{ flexDirection: "row", alignItems: props.vAlign, ...props.style }}>
          {props.users.map((user, i) => (
            <Fragment key={user.id}>
              <UserDisplay user={user} mode="avatar-and-text" size="small" key={user.id} preferDisplay={props.preferDisplay} />
              <BodyTextOld style={{ marginLeft: BASE_MARGIN / 3, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                {i === props.users.length - 2 ? " & " : i < props.users.length - 2 ? ", " : ""}
              </BodyTextOld>
            </Fragment>
          ))}
        </View>
      )}
    </>
  )
}

export function UserDisplayRowInline(props: {
  users: UsersRow | Readonly<UsersRow>
  preferDisplayName?: true
  preferPublishingName?: true
}) {
  return (
    <>
      {props.users.map((user, i) => (
        <Fragment key={user.id}>
          <UserDisplay
            user={user}
            mode="avatar-and-text"
            size="small"
            preferDisplay={props.preferDisplayName}
            preferPublishingName={props.preferPublishingName}
          />
          {i === props.users.length - 2 ? " & " : i < props.users.length - 2 ? ", " : ""}
        </Fragment>
      ))}
    </>
  )
}
